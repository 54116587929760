import React from 'react';
import ReactDOM from 'react-dom';

import { utils, promiseUtils } from '../../lib';
import { request, Dialog, clipboard } from '../../base';
import { buildAction, filterAllowedActions } from '../../base/action';
import { toast } from '../../toast';
import iboardsApi from '../api';
import { ExportPresentationStatus } from './job';

import QRDropdown from '../../share/QRDropdown';

const presentationApi = {};

const presentationActions = {
    deleteForever: buildAction({
        action: (presentations) => {
            return Dialog.open({
                component: 'dialog-presentation-delete-forever',
                ctx: {
                    ctx: { items: presentations }
                }
            }).result.then(() => {
                return promiseUtils.partition(
                    promiseUtils.mapWithContext(presentations, iboardsApi.presentation.deleteForever)
                ).then(([resolved, rejected]) => {
                    resolved.length && toast('DELETE_PRESENTATION_COMPLETE', { count: resolved.length });
                    rejected.length && toast('DELETE_PRESENTATION_FAIL', { count: rejected.length });
                    return Promise.resolve(resolved.map(r => r.context));
                });
            });
        },
        properties: {
            multiple: true,
            isAllowed: p => p.is_deleted,
            title: gettext('Delete forever'),
            icon: 'icon-trash',
            GAAction: 'Presentation_Toolbar',
            GALabel: 'Click_Delete_Forever'
        }
    }, 'deleteForever', 'presentation'),
    restore: buildAction({
        action: (presentations) => {
            return Dialog.open({
                component: 'dialog-presentation-restore',
                ctx: {
                    ctx: { items: presentations }
                }
            }).result.then(() => {
                return promiseUtils.partition(
                    promiseUtils.mapWithContext(presentations, iboardsApi.presentation.restore)
                ).then(([resolved, rejected]) => {
                    resolved.length && toast('RESTORE_PRESENTATION_COMPLETE', { count: resolved.length });
                    rejected.length && toast('RESTORE_PRESENTATION_FAIL', { count: rejected.length });
                    return Promise.resolve(resolved.map(r => r.context));
                });
            });
        },
        properties: {
            multiple: true,
            isAllowed: p => p.is_deleted,
            title: gettext('Restore'),
            icon: 'icon-restore',
            GAAction: 'Presentation_Toolbar',
            GALabel: 'Click_Presentation_Restore'
        }
    }, 'restore', 'presentation'),
    delete: buildAction({
        action: function (presentations) {
            return Dialog.open({
                component: 'dialog-delete-presentation',
                ctx: {
                    ctx: { items: presentations }
                }
            }).result.then(() => {
                const updatedPresentations = presentations.map(presentation => presentation.update({ is_deleted: true }));
                return promiseUtils.partition(
                    promiseUtils.mapWithContext(
                        updatedPresentations,
                        presentationApi.updatePresentation,
                        ['is_deleted']
                    )
                ).then(([resolved, rejected]) => {
                    resolved.length && toast('PRESENTATION_DELETE_COMPLETE', {
                        count: resolved.length
                    });
                    rejected.length && toast('PRESENTATION_DELETE_FAIL', {
                        count: rejected.length
                    });
                    return Promise.resolve(null).then(() => resolved.map(r => r.context));
                });
            });
        },
        properties: {
            multiple: true,
            isAllowed: p => !p.is_deleted,
            title: gettext('Delete'),
            icon: 'icon-trash',
            GAAction: 'Presentation_Toolbar',
            GALabel: 'Click_Presentation_Soft_Delete'
        }
    }, 'delete', 'presentation'),
    rename: buildAction({
        action: function (presentations) {
            const presentation = presentations[0];

            return Dialog.open({
                component: 'dialog-rename-presentation',
                ctx: {
                    ctx: { presentation }
                }
            }).result.then(newTitle => {
                const updatedPresentation = presentation.update({ name: newTitle });

                return presentationApi.updatePresentation(updatedPresentation)
                    .then(data => {
                        toast('PRESENTATION_RENAME_COMPLETE');
                        return Promise.resolve([Presentation.fromApi(data)]);
                    })
                    .catch(error => {
                        toast('PRESENTATION_RENAME_FAIL');
                        return Promise.resolve(error);
                    });
            });
        },
        properties: {
            multiple: false,
            isAllowed: p => !p.is_deleted,
            title: gettext('Rename'),
            icon: 'icon-rename',
            GAAction: 'Presentation_Toolbar',
            GALabel: 'Click_Presentation_Rename'
        }
    }, 'rename', 'presentation'),
    download: buildAction({
        action: function (presentations) {
            return presentations.map(p => iboardsApi.presentation.downloadPresentation(p.id));
        },
        properties: {
            multiple: true,
            isAllowed: p => !p.is_deleted,
            title: gettext('Download'),
            icon: 'icon-download',
            GAAction: 'Presentation_Toolbar',
            GALabel: 'Click_Presentation_Download'
        }
    }, 'download', 'presentation'),
    duplicate: buildAction({
        action: function (presentations) {
            toast('PRESENTATION_DUPLICATION_STARTED');
            const presentation = presentations[0];
            const view = presentation.items.view;
            return iboardsApi.presentation.duplicatePresentation(presentation.id)
                .then(data => {
                    const newPresentation = view.itemViewModelFactory(data);
                    view.presentations.add(newPresentation);
                    view.sort();
                    newPresentation.open();
                    toast('PRESENTATION_DUPLICATION_DONE');
                });
        },
        properties: {
            multiple: false,
            isAllowed: p => !p.is_deleted,
            title: gettext('Duplicate'),
            icon: 'icon-duplicate',
            GAAction: 'Presentation_Toolbar',
            GALabel: 'Click_Presentation_Duplicate'
        }
    }, 'duplicate', 'presentation'),
    migrate: buildAction({
        action: function (presentations) {
            toast('PRESENTATION_MIGRATION_STARTED');
            const presentation = presentations[0];
            const view = presentation.items.view;
            return iboardsApi.presentation.migratePresentation(presentation.id)
                .then(data => {
                    const newPresentation = view.itemViewModelFactory(data);
                    view.presentations.add(newPresentation);
                    view.sort();
                    newPresentation.open();
                    toast('PRESENTATION_MIGRATION_DONE');
                })
                .catch(() => toast('PRESENTATION_MIGRATION_FAIL'));
        },
        properties: {
            multiple: false,
            isAllowed: (pres) => pres.version !== 2 && !pres.is_deleted,
            title: gettext('Migrate to new'),
            icon: 'icon-migrate-presentation',
            GAAction: 'Presentation_Toolbar',
            GALabel: 'Click_Presentation_Migrate'
        }
    }, 'migrate', 'presentation')
};

class Presentation {
    static create (data) {
        return new Presentation(data);
    }

    static fromApi (data) {
        return Presentation.create(data);
    }

    constructor (data) {
        this.id = data.uuid;
        this.name = data.title;

        this.is_deleted = data.is_deleted;
        this._dateCreated = data.date_created;
        this._lastModified = data.date_modified;
        this.dateCreated = utils.formatDateTime(this._dateCreated);
        this.lastModified = utils.formatDateTime(this._lastModified);
        this.thumbnail = data.thumbnail;
        this.props = data.props;
        this.version = data.version;

        this.isExporting = ko.observable(false);
        this.exportState = ko.observable();
        this.exportError = ko.observable();
        this.exportMessage = ko.observable();

        this.actions = filterAllowedActions(presentationActions, this);
        this.actionNames = Object.keys(this.actions);

        this.qrRenderNodeIdList = `list__qr-code-${this.id}`;
        this.qrRenderNodeIdThumb = `thumb__qr-code-${this.id}`;
    }

    renderQR () {
        const thumbNode = document.getElementsByClassName(this.qrRenderNodeIdThumb)[0];
        const listNode = document.getElementsByClassName(this.qrRenderNodeIdList)[0];

        ReactDOM.render(
            <QRDropdown
                id={this.qrRenderNodeIdThumb}
                url={this.getLink()}
                node={thumbNode}
                togglerClass='flag'
            />,
            thumbNode
        );
        ReactDOM.render(
            <QRDropdown
                id={this.qrRenderNodeIdList}
                url={this.getLink()}
                node={listNode}
                togglerClass='flag'
            />,
            listNode
        );
    }

    openQR = (selector) => () => {
        Array.from(document.getElementsByClassName(selector))[0].firstChild.click();
    };

    init () {
        this.renderQR();
    }

    getIcon () {
        return 'icon-presentation';
    }

    getUrl () {
        return `/portal/presentation/${this.id}/`;
    }

    reportProgress (data) {
        const { state, progress, error } = data.job;

        this.isExporting(true);
        this.exportState(state);
        this.exportMessage(ExportPresentationStatus[state].text + (progress ? ` ${progress}%` : ''));
        this.exportError(error);

        if (state === 'done') {
            setTimeout(() => this.isExporting(false), 5000);
        }
    }

    update (data) {
        const result = utils.copyObject(this);
        Object.assign(result, data);
        return result;
    }

    hasAction (action) {
        return !!this.actions[action];
    }

    doAction (action, context = null) {
        return () => this.actions[action].action.call(context, this);
    }

    forApiCall (updatedFields) {
        const updated = {};
        updatedFields.forEach(field => {
            if (field === 'title') {
                updated[field] = this.name;
            } else {
                updated[field] = this[field];
            }
        });
        return updated;
    }

    equals (other) {
        return this.id === other.id;
    }

    getLink () {
        return `${window.location.origin}/presentations/${this.id}/`;
    }

    copyToClipboard () {
        clipboard.copyToClipboard(this.getLink())
            .then(() => toast('COPIED'));
    };
}

Object.assign(presentationApi, {
    allPresentations () {
        return request({
            url: `${Settings.API_V1_ROOT}iboards/`
        });
    },
    getPresentation (presentationId) {
        return request({
            url: `${Settings.API_V1_ROOT}iboards/${presentationId}/`
        })
            .then(data => Presentation.fromApi(data));
    },
    createPresentation (name) {
        return request({
            url: `${Settings.API_V1_ROOT}iboards/`,
            method: 'POST',
            data: { title: name.replace(/\s+/g, ' ').trim() },
            contentType: 'application/json; charset=utf-8'
        })
            .then(data => data);
    },
    updatePresentation (presentation, updatedFields = ['title']) {
        return request({
            url: `${Settings.API_V1_ROOT}iboards/${presentation.id}/`,
            method: 'PATCH',
            data: presentation.forApiCall(updatedFields)
        });
    },
    deletePresentation (presentation) {
        return request({
            url: `${Settings.API_V1_ROOT}iboards/${presentation.id}/`,
            method: 'DELETE'
        });
    }
});

export {
    Presentation,
    presentationApi
};
