import { queryString, promiseUtils, FileTypes } from '../lib';
import { Dialog, createRequest } from '../base';
import { toast } from '../toast';
import { buildAction } from '../base/action';
import { progressTracker } from '../progress/progress';
import ProgressDialog from '../progress/progress-dialog.vm';
import { Job, JobProcess } from '../job/job';
import assetApi from './api';
import integrations from '../integrations/integrations';
import { isLinkView } from '../preview/link';
import { presentationApi } from '../iboards/presentations-view/api';
import { PRESENTATION_ALLOWED_FILE_TYPES } from '../iboards/constants';
import { Asset } from './asset';
import { StorageProvider } from '../browser/storage-providers';
import { FeatureSet, FeatureType } from '../features/feature-types';

const originalFolderUrlFactory = {
    dropbox: asset => {
        return integrations.get().then(integrations => {
            const dropbox = integrations.find(
                i => i.type === 'storage' && i.provider === 'dropbox'
            ).data;
            const baseUrl = 'https://www.dropbox.com/home/';
            const path = asset.prefix.replace(/\/+$/, '').replace(/^\/+$/, '');
            return dropbox.access_type === 'dropbox_folder'
                ? `${baseUrl}Apps/VCS Test Limited Access/${path}`
                : `${baseUrl}${path}`;
        });
    },
    google_drive: asset =>
        Promise.resolve(`https://drive.google.com/drive/folders/${asset.srcId}`)
};

const isModifiable = (asset) => {
    return !isLinkView() && (asset.isOwn || asset.hasPermission('modify'));
};

const ownOutsideLink = (asset) => {
    return !isLinkView() && asset.isOwn;
};

const isSearchView = () => {
    return window.location.pathname.startsWith('/portal/files/search/');
};

const goToFolderAction = {
    action: () => Promise.resolve(null),
    properties: {
        multiple: false,
        isAllowed (asset) {
            return isSearchView() && asset.isValid;
        },
        url: asset => {
            return asset.folder
                ? `/portal/files/${asset.storageType}/${asset.owner}/${asset.folder}/`
                : `/portal/files/${asset.storageType}/${asset.owner}/`;
        },
        title: gettext('Go to folder'),
        icon: 'icon-go-to-folder'
    }
};

const actions = {
    goToFolder: goToFolderAction,
    viewOriginal: {
        action: function (assets) {
            const asset = assets[0];
            return originalFolderUrlFactory[asset.storageType](asset).then(url => {
                const a = document.createElement('a');
                a.href = url;
                a.target = '_blank';
                // firefox doesn't support `a.click()`...
                a.dispatchEvent(new window.MouseEvent('click'));
            });
        },
        properties: {
            multiple: false,
            isAllowed (asset) {
                return false &&
                    asset.isValid &&
                    asset.isFolder &&
                    ownOutsideLink(asset) &&
                    (asset.storageType === 'dropbox' || asset.storageType === 'google_drive');
            },
            title: gettext('View original'),
            icon: 'icon-view',
            GALabel: 'Click_View_Original'
        }
    },
    view: {
        action: function (assets) {
            const asset = assets[0];
            return Promise.resolve(asset.view && asset.view(assets));
        },
        properties: {
            multiple: true,
            isAllowed (asset) {
                return asset.isValid && !asset.isFolder;
            },
            title: gettext('View'),
            icon: 'icon-view',
            GALabel: 'Click_View'
        }
    },
    download: {
        action: async function (assets) {
            let url = null;
            const asset = assets[0];
            if (assets.every(a => a.storageType === StorageProvider.S3.storageType) &&
                (assets.length > 1 || assets[0].isFolder)) {
                const linkView = isLinkView();
                url = await actions.request({
                    url: `${Settings.API_ROOT}download/:stream/`,
                    method: 'POST',
                    data: {
                        file_versions: assets.map(asset => ({
                            path: asset.mountRealPath(),
                            provider: asset.storageType,
                            owner: asset.owner,
                            src_id: asset.srcId,
                            is_folder: asset.isFolder
                        })),
                        ...(linkView
                            ? {
                                link_uuid: linkView.uuid
                            }
                            : {})
                    }
                }).then(data => data.url);
            } else {
                url = await $.getJSON(queryString.buildUrl(asset.downloadUrl, {
                    web: 'on',
                    download: 'on',
                    response_type: 'data',
                    viewable: 'off'
                })).then(data => data.url);
            }
            const a = document.createElement('a');
            a.download = asset.name;
            a.href = url;
            // firefox doesn't support `a.click()`...
            a.dispatchEvent(new window.MouseEvent('click'));

            const linkInfo = assets[0].ownerInfo.link;
            linkInfo && linkInfo.visitCountOnDownload && assetApi.linkVisit(linkInfo.uuid);

            return Promise.resolve(null);
        },
        properties: {
            multiple: (asset) => asset.storageType === StorageProvider.S3.storageType,
            isAllowed (asset) {
                const isS3Folder = asset.isFolder && asset.storageType === StorageProvider.S3.storageType;

                return asset.isValid &&
                    (asset.isFile || isS3Folder) &&
                    asset.hasPermission('download') &&
                    asset.isAvailable() &&
                    asset.exists &&
                    (asset.isFile ? asset.size > 0 : true) &&
                    ![FileTypes.connect_cad, FileTypes.vcdoc].includes(asset.fileType.type);
            },
            title: gettext('Download'),
            icon: 'icon-download',
            GALabel: 'Click_Download'
        }
    },
    delete: {
        action: function (assets) {
            return Dialog.open({
                component: 'dialog-delete',
                ctx: {
                    ctx: {
                        items: assets,
                        isCollaborativeFolder: false
                    }
                }
            }).result.then(() => {
                const folders = assets.filter(asset => asset.isFolder).length;
                folders && toast('DELETE_FOLDER_SUBMIT', {
                    count: folders
                });

                return promiseUtils.partition(
                    promiseUtils.mapWithContext(assets, assetApi.delete)
                ).then(([resolved, rejected]) => {
                    // Delete is completed only for files
                    const deletedFiles = resolved.filter(r => r.context.isFile).map(r => r.context);
                    deletedFiles.length && toast('DELETE_COMPLETE', {
                        count: deletedFiles.length
                    });

                    rejected.length && toast('DELETE_FAIL', {
                        count: rejected.length
                    });

                    return Promise.resolve(null).then(() => deletedFiles);
                });
            });
        },
        properties: {
            multiple: true,
            isAllowed (asset) {
                const googleCanTrash = asset.storageType === StorageProvider.GOOGLE_DRIVE.storageType
                    ? asset.flags.is_google_trashable
                    : true;

                const mountPoint = asset.ownerInfo.mountPoint;
                return asset.isValid &&
                    isModifiable(asset) &&
                    asset.isLatestVersion &&
                    asset.exists &&
                    googleCanTrash &&
                    (!mountPoint || mountPoint.mount_path !== asset.prefix);
            },
            title: gettext('Delete'),
            icon: 'icon-trash',
            GALabel: 'Click_Delete'
        }
    },
    deleteForever: {
        action: function (assets) {
            return Dialog.open({
                component: 'dialog-delete-forever',
                ctx: {
                    ctx: { items: assets }
                }
            }).result.then(() => {
                const folders = assets.filter(asset => asset.isFolder).length;
                folders && toast('DELETE_FOLDER_SUBMIT', {
                    count: folders
                });

                return promiseUtils.partition(
                    promiseUtils.mapWithContext(assets, assetApi.deleteForever)
                ).then(([resolved, rejected]) => {
                    // Delete is completed only for files
                    const deletedFiles = resolved.filter(r => r.context.isFile).map(r => r.context);
                    deletedFiles.length && toast('DELETE_COMPLETE', {
                        count: deletedFiles.length
                    });

                    rejected.length && toast('DELETE_FAIL', {
                        count: rejected.length
                    });

                    return Promise.resolve(null).then(() => deletedFiles);
                });
            });
        },
        properties: {
            multiple: true,
            isAllowed (asset) {
                return asset.isValid &&
                    ownOutsideLink(asset) &&
                    !asset.exists;
            },
            title: gettext('Delete forever'),
            icon: 'icon-trash',
            GALabel: 'Click_Delete_Forever'
        }
    },
    deleteVersion: {
        action: function (assets) {
            return Dialog.open({
                component: 'dialog-delete-version',
                ctx: {}
            }).result.then(() => {
                const asset = assets[0];
                return assetApi.deleteVersion(asset, asset.versionId);
            });
        },
        properties: {
            multiple: true,
            isAllowed (asset) {
                return asset.isValid &&
                    isModifiable(asset) &&
                    asset.storageType !== 'dropbox' && asset.storageType !== 'google_drive' && asset.storageType !== 'one_drive';
            },
            title: gettext('Delete'),
            icon: 'icon-remove-content',
            GALabel: 'Click_Delete_Version'
        }
    },
    restore: {
        action: function (assets, restoreType) {
            return Dialog.open({
                component: restoreType === 'versions' ? 'dialog-restore-version' : 'dialog-restore',
                ctx: {
                    ctx: { items: assets }
                }
            }).result.then(() => {
                const folders = assets.filter(asset => asset.isFolder).length;
                folders && toast('RESTORE_FOLDER_SUBMIT', {
                    count: folders
                });

                if (assets[0].routerCtx) {
                    const router = assets[0].routerCtx.router;
                    router.ctx.path !== '/trash/' &&
                        setTimeout(() => router.update('trash/'), 100);
                }

                return promiseUtils.partition(
                    assets.map(asset => {
                        return assetApi.restore(asset)
                            .then(() => {
                                return asset.isFile
                                    ? assetApi.getLatest(asset)
                                        .then(data => Asset.fromFile(
                                            data,
                                            {
                                                ownerInfo: asset.ownerInfo,
                                                sharingInfo: asset.sharingInfo,
                                                assetActions: asset.actions
                                            }
                                        ))
                                    : Asset.update(asset, (data, options) => {
                                        data.exists = true;
                                    });
                            });
                    })
                ).then(([resolved, rejected]) => {
                    const restoredFiles = resolved.filter(r => r.isFile);
                    restoredFiles.length && toast('RESTORE_COMPLETE', {
                        count: restoredFiles.length
                    });

                    rejected.length && toast('RESTORE_FAIL', {
                        count: rejected.length
                    });

                    const conflictFiles = rejected.reduce((a, b) => {
                        return b.response.status === 409
                            ? a.concat(b.response.data.invalid)
                            : a;
                    },
                    []);

                    conflictFiles.length && Dialog.open({  // eslint-disable-line
                        component: 'dialog-conflict-in-restore',
                        ctx: {
                            ctx: { items: conflictFiles }
                        }
                    }).result;

                    return Promise.resolve(null).then(() => restoredFiles);
                });
            });
        },
        properties: {
            multiple: true,
            isAllowed (asset) {
                return asset.isValid &&
                    asset.storageType !== 'google_drive' &&
                    (
                        asset.isFile ||
                        asset.isFolder && asset.storageType === Settings.DEFAULT_STORAGE
                    ) &&
                    (
                        (asset.exists && isModifiable(asset) && asset.fileType.type !== FileTypes.vwxp) ||
                        (!asset.exists && ownOutsideLink(asset))
                    ) &&
                    ((asset.exists && !asset.isLatestVersion) || (!asset.exists && asset.isLatestVersion));
            },
            title: gettext('Restore'),
            icon: 'icon-restore',
            GALabel: 'Click_Restore'
        }
    },
    share: {
        action: function (assets) {
            return Dialog.open({
                component: 'dialog-share',
                ctx: {
                    ctx: { items: assets }
                }
            }).result;
        },
        properties: {
            multiple: true,
            isAllowed (asset) {
                return asset.isValid &&
                    isModifiable(asset) &&
                    asset.isLatestVersion &&
                    asset.exists;
            },
            title: gettext('Share'),
            icon: 'icon-share',
            GALabel: 'Click_Share'
        }
    },
    stopSharing: {
        action: function (assets) {
            return Dialog.open({
                component: 'dialog-confirm-stop-sharing',
                ctx: {
                    ctx: { items: assets }
                }
            }).result.then(() => {
                const links = assets.filter(asset => !!asset.sharingInfo.link);
                const shares = assets.filter(asset => asset.isShared);
                const unshareRequests = promiseUtils.mapWithContext(shares, assetApi.unshare);
                const removeLinkRequests = promiseUtils.mapWithContext(links, assetApi.removeLink);
                return promiseUtils.partition(unshareRequests.concat(removeLinkRequests))
                    .then(([resolved, rejected]) => {
                        resolved.length && toast('UNSHARE_COMPLETE', {
                            count: resolved.length
                        });
                        rejected.length && toast('UNSHARE_FAIL', {
                            count: rejected.length
                        });
                        return Promise.resolve(null).then(() => resolved.map(r => r.context.unshare(true)));
                    });
            });
        },
        properties: {
            multiple: true,
            isAllowed: () => false,
            title: gettext('Stop sharing'),
            icon: 'icon-stop-sharing',
            GALabel: 'Click_Unshare'
        }
    },
    manageLinks: {
        action: function (assets) {
            return Dialog.open({
                component: 'dialog-manage-links',
                ctx: {
                    ctx: { items: assets }
                }
            }).result;
        },
        properties: {
            multiple: true,
            isAllowed (asset) {
                return asset.isValid &&
                    ownOutsideLink(asset) &&
                    asset.isLatestVersion &&
                    asset.exists;
            },
            title: gettext('Share link'),
            icon: 'icon-link',
            GALabel: 'Click_Email'
        }
    },
    rename: {
        action: function ([asset]) {
            return Dialog.open({
                component: 'dialog-rename',
                ctx: {
                    ctx: { item: asset }
                }
            }).result;
        },
        properties: {
            multiple: false,
            isAllowed (asset) {
                return asset.isFile &&
                    asset.isValid &&
                    isModifiable(asset) &&
                    asset.isAvailable() &&
                    asset.isNameValid &&
                    asset.exists && asset.storageType === Settings.DEFAULT_STORAGE &&
                    Settings.NEW_FEATURES.isActive('rename');
            },
            title: gettext('Rename'),
            icon: 'icon-rename',
            GALabel: 'Click_Rename'
        }
    },
    exportPdf: {
        action: requireVCSFeature(function (assets) {
            const dialog = Dialog.open({
                component: 'dialog-confirm-export-pdf',
                ctx: {
                    ctx: { assets }
                }
            });

            return dialog.result.then(() => {
                return promiseUtils.partition(
                    assets.map(asset =>
                        assetApi.exportPdf(asset)
                            .then(data => {
                                progressTracker.add(JobProcess.fromJob(new Job(data)));
                                toast('JOB_SUBMIT');
                                return Promise.resolve(data);
                            })
                            .catch(error => {
                                toast('JOB_SUBMIT_FAIL');
                                return Promise.reject(error);
                            })));
            });
        }, FeatureType.JOBS.extend('export_pdf')),
        properties: {
            multiple: true,
            isAllowed (asset) {
                return asset.isValid &&
                    [
                        FileTypes.vwx,
                        FileTypes.vwxp
                    ].includes(asset.fileType.type) &&
                    asset.isFile &&
                    isModifiable(asset) &&
                    asset.isAvailable() &&
                    asset.exists &&
                    asset.isLatestVersion;
            },
            title: gettext('Generate PDF'),
            icon: 'icon-export-pdf',
            GALabel: 'Click_Export_Pdf'
        }
    },
    trainImageStyle: {
        action: requireVCSFeature(function (assets) {
            const asset = assets[0];
            const data = {
                file_version: {
                    container: 'vectorworks-devel-storage',
                    provider: 's3',
                    file_type: 'JPG',
                    path: asset.prefix,
                    id: asset.versionId,
                    version_id: asset.versionId
                },
                job_type: 'extract_style',
                status: 'processing'
            };
            const JobItem = JobProcess.fromJob(new Job(data));
            progressTracker.add(JobItem);
            ProgressDialog.open();

            const time = 7000;

            for (let i = 0; i <= 100; i += 10) {
                setTimeout(function () {
                    JobItem.percentComplete(i);
                }, i * time / 100);
            }

            setTimeout(function () {
                JobItem.finish();
                const copyAssetData = Object.assign(asset.data,
                    {
                        thumbnail: '',
                        prefix: asset.data.prefix + '.ckpt',
                        file_type: 'CKPT'
                    }
                );
                const assetCopy = asset.assets.view.itemViewModelFactory(
                    Asset.fromAsset({ asset: copyAssetData }, { assetActions: actions }),
                    false
                );
                asset.items.add(assetCopy);
                asset.deselect();
                assetCopy.select();
            }, time);

            toast('JOB_SUBMIT');
            return Promise.resolve(data);
        }, FeatureType.JOBS.extend('image_style')),
        properties: {
            multiple: true,
            isAllowed (asset) {
                return false &&
                     asset.isValid &&
                     asset.fileType.type === FileTypes.image &&
                     asset.isFile &&
                    isModifiable(asset) &&
                     asset.isAvailable() &&
                     asset.exists &&
                     asset.isLatestVersion;
            },
            title: gettext('Train Image Style'),
            icon: 'icon-nast',
            GALabel: ''
        }
    },
    generate3DModel: {
        action: requireVCSFeature(function (assets) {
            return Dialog.open({
                component: 'dialog-3d-generate',
                ctx: {
                    ctx: { items: assets }
                }
            });
        }, FeatureType.JOBS.extend('distill')),
        properties: {
            multiple: true,
            isAllowed (asset) {
                return asset.isValid &&
                    [
                        FileTypes.vwx,
                        FileTypes.vwxp
                    ].includes(asset.fileType.type) &&
                    asset.isFile &&
                    isModifiable(asset) &&
                    asset.isAvailable() &&
                    asset.exists &&
                    asset.isLatestVersion;
            },
            title: gettext('Generate 3D model'),
            icon: 'icon-export-3d',
            GALabel: 'Click_Gnerate_3d'
        }
    },
    revitExport: {
        action: requireVCSFeature(function (assets) {
            return Dialog.open({
                component: 'dialog-revit-export',
                ctx: {
                    ctx: { items: assets },
                    submitOnClose: true
                }
            });
        }, FeatureType.JOBS.extend('revit_export')),
        properties: {
            multiple: true,
            isAllowed (asset) {
                return asset.isValid &&
                    [FileTypes.vwx].includes(asset.fileType.type) &&
                    asset.isFile &&
                    isModifiable(asset) &&
                    asset.isAvailable() &&
                    asset.exists &&
                    asset.isLatestVersion &&
                    Settings.NEW_FEATURES.isActive('revit_export');
            },
            title: gettext('Export Revit'),
            icon: 'icon-export-rvt',
            GALabel: 'Click_Revit_Export'
        }
    },
    newTask: {
        action: function (assets) {
            return Dialog.open({
                component: 'dialog-task',
                ctx: {
                    ctx: { items: assets }
                }
            }).result;
        },
        properties: {
            multiple: false,
            isAllowed (asset) {
                return asset.isValid &&
                    [
                        FileTypes.vwx,
                        FileTypes.vwxp
                    ].includes(asset.fileType.type) &&
                    asset.isFile &&
                    ownOutsideLink(asset) &&
                    asset.isAvailable() &&
                    asset.exists &&
                    asset.isLatestVersion;
            },
            title: gettext('Auto Process...'),
            icon: 'icon-auto-process',
            GALabel: 'Click_New_Task'
        }
    },
    addToBluebeamSession: {
        action: function (assets) {
            if (integrations.hasIntegrationWith('bluebeam')) {
                const inSession = assets.filter(a => a.bluebeamSessions && a.bluebeamSessions.sessions.length);
                if (inSession.length) {
                    return Dialog.open({
                        component: 'dialog-file-already-in-session',
                        ctx: {
                            ctx: {
                                inSession,
                                assets
                            }
                        }
                    }).result
                        .then(() =>
                            Dialog.open({
                                component: 'dialog-bluebeam',
                                ctx: {
                                    ctx: { items: assets }
                                }
                            }).result);
                } else {
                    return Dialog.open({
                        component: 'dialog-bluebeam',
                        ctx: {
                            ctx: { items: assets }
                        }
                    }).result;
                }
            } else {
                return Dialog.open({
                    component: 'dialog-integrate-bluebeam',
                    ctx: {
                        ctx: {}
                    }
                }).result;
            }
        },
        properties: {
            multiple: true,
            isAllowed (asset) {
                return (asset.isValid &&
                    asset.fileType.type === FileTypes.pdf &&
                    asset.isFile &&
                    isModifiable(asset) &&
                    asset.isAvailable() &&
                    asset.isLatestVersion &&
                    asset.exists &&
                    (asset.bluebeamSessions && asset.bluebeamSessions.integrated));
            },
            title: gettext('Add to Studio Session'),
            icon: 'icon-start-session',
            GALabel: 'Click_Bluebeam'
        }
    },
    openBlueBeamSession: {
        action: function (assets) {
            const sessions = assets[0].bluebeamSessions.sessions;
            const router = assets[0].routerCtx.router;
            if (sessions.length === 1) {
                router.update(`${Settings.BLUEBEAM_URL}${sessions[0].id}/`);
            } else {
                return Dialog.open({
                    component: 'dialog-open-session',
                    ctx: {
                        ctx: {
                            sessions,
                            router
                        }
                    }
                }).result.then(() => {});
            }
            return Promise.resolve();
        },
        properties: {
            multiple: false,
            isAllowed (asset) {
                return (asset.isValid &&
                    asset.fileType.type === FileTypes.pdf &&
                    asset.isFile &&
                    isModifiable(asset) &&
                    asset.isAvailable() &&
                    asset.exists);
            },
            title: gettext('Open Studio Session'),
            icon: 'icon-start-session',
            GALabel: 'Click_Bluebeam'
        }
    },
    photogrammetry: {
        action: requireVCSFeature(function (assets) {
            return Dialog.open({
                component: 'dialog-photogrammetry-job',
                ctx: {
                    ctx: { items: assets }
                }
            }).result.then(() => {});
        }, FeatureType.JOBS.extend('photogrammetry')),
        properties: {
            multiple: function (asset) {
                return !asset.isFolder;
            },
            isAllowed (asset) {
                return (asset.isValid &&
                    isModifiable(asset) &&
                    asset.isLatestVersion &&
                    asset.exists) &&
                    ((asset.fileType.type === FileTypes.image ||
                        asset.fileType.type === FileTypes.panorama) ||
                        ['.heic', '.heif', '.cr2'].includes(asset.extension.toLowerCase()) ||
                        asset.isFolder);
            },
            title: gettext('Photos to 3D model'),
            icon: 'icon-photogrammetry',
            GALabel: 'Click_Photogrammetry'
        }
    },
    styleTransfer: {
        action: requireVCSFeature(function (assets) {
            return Dialog.open({
                component: 'dialog-style-transfer',
                ctx: {
                    ctx: { items: assets }
                }
            }).result.then(params => {
                return promiseUtils.partition(
                    assets.map(asset =>
                        assetApi.styleTransfer(asset, params)
                            .then(data => {
                                toast('JOB_SUBMIT');
                                return Promise.resolve(data);
                            })
                            .catch(
                                error => {
                                    if (error.response.status === 409) {
                                        toast('JOB_EXISTS');
                                    } else {
                                        toast('JOB_SUBMIT_FAIL');
                                    }
                                    return Promise.reject(error);
                                })));
            });
        }, FeatureType.JOBS.extend('style_transfer')),
        properties: {
            multiple: function (asset) {
                return !asset.isFolder;
            },
            isAllowed (asset) {
                return asset.isValid &&
                    isModifiable(asset) &&
                    asset.isLatestVersion &&
                    asset.exists &&
                    [FileTypes.image, FileTypes.panorama].includes(asset.fileType.type);
            },
            title: gettext('Stylize image'),
            icon: 'icon-nast',
            GALabel: 'Click_Style_Transfer'
        }
    },
    upsampling: {
        action: requireVCSFeature(function (assets) {
            return Dialog.open({
                component: 'dialog-upsampling',
                ctx: {
                    ctx: { items: assets }
                }
            }).result.then(params => {
                return promiseUtils.partition(
                    assets.map(asset =>
                        assetApi.upsampling(asset, params)
                            .then(data => {
                                toast('JOB_SUBMIT');
                                return Promise.resolve(data);
                            })
                            .catch(
                                error => {
                                    if (error.response.status === 409) {
                                        toast('JOB_EXISTS');
                                    } else {
                                        toast('JOB_SUBMIT_FAIL');
                                    }
                                    return Promise.reject(error);
                                })));
            });
        }, FeatureType.JOBS.extend('upsampling')),
        properties: {
            multiple: function (asset) {
                return !asset.isFolder;
            },
            isAllowed (asset) {
                return asset.isValid &&
                    isModifiable(asset) &&
                    asset.isLatestVersion &&
                    asset.exists &&
                    [FileTypes.image, FileTypes.panorama].includes(asset.fileType.type);
            },
            title: gettext('Upsample image'),
            icon: 'icon-upsample',
            GALabel: 'Click_Upsampling'
        }
    },
    addToPresentation: {
        action: requireVCSFeature(function (assets) {
            return presentationApi.allPresentations().then(presentations => {
                Dialog.open({
                    component: 'dialog-add-to-presentation',
                    ctx: {
                        items: assets,
                        presentations: presentations.results
                    }
                });
            });
        }, FeatureType.PRESENTATIONS),
        properties: {
            multiple: true,
            isAllowed (asset) {
                return asset.isValid &&
                    isModifiable(asset) &&
                    asset.exists &&
                    asset.isFile &&
                    FileTypes.isFileTypeAllowed(asset.prefix, PRESENTATION_ALLOWED_FILE_TYPES);
            },
            title: gettext('Add to presentation'),
            icon: 'icon-add-to-presentation',
            GALabel: 'Click_AddToPresentations'
        }
    }
};

Object.defineProperty(actions, 'request', {
    get () {
        if (!actions._request) {
            actions._request = createRequest();
        }
        return actions._request;
    }
});

function requireVCSFeature (func, FeatureType) {
    return function (...args) {
        if (!FeatureSet.hasFeature(FeatureType)) {
            Dialog.open({
                component: 'dialog-enable-feature',
                ctx: {
                    ctx: { dismissAction: 'stay-here' },
                    template: { name: 'dialog-enable-feature' }
                }
            });
            return;
        }
        return func.apply(this, args);
    };
}

const actionsModule = Object.keys(actions)
    .reduce((module, name) => {
        module[name] = buildAction(actions[name], name, 'asset');
        return module;
    }, {});

export {
    actionsModule as actions,
    requireVCSFeature,
    goToFolderAction
};
