import { filePreview } from '../preview/file-preview.vm';
import { toast } from '../toast';
import { progressTracker } from '../progress/progress';
import { trackPresentationJobs } from '../iboards/presentations-view/job';
import { trackCopyFiles } from '../job/copy/listener';
import { Dialog, pubsub } from '../base';
import pushNotifier from '../base/push-notifier';
import { utils } from '../lib';
import parser from 'ua-parser-js';

import { Job, jobApi } from './api';
import {
    JobStatus,
    JobIcons,
    failedReasons,
    handleLastUpdate,
    progressFromLastUpdate
} from './utils';
import { StorageProvider } from '../browser/storage-providers';
import { loadGooglePages, loadOneDrivePages } from '../Sidebar/Integrations/api';

class JobProcess {
    static fromJob (job) {
        return new JobProcess(job.id, job, job.file, JobStatus[job.data.status], job.container);
    }

    constructor (job_id, job, file, status, container) {
        this.id = job_id;
        this.job = job;
        this.file = file;
        this.name = job.name;
        this.origin = getJobOrigin(job.data.origin);
        this.path = `${container} / ${file.formatedPath()}`;
        this.size = '   -';
        this.status = ko.observable(status);
        this.sequenceNumber = ko.observable(0);
        this.lastUpdate = ko.observable(
            this.job.data.lastUpdate
                ? handleLastUpdate(this.job.data.lastUpdate)
                : null
        );
        this.percentComplete = ko.observable(
            job.data.status === 'processing'
                ? progressFromLastUpdate(job.data.lastUpdate)
                : 0
        );
        this.progress = ko.observable(null);
        this.icon = JobIcons[job.type] || JobIcons.default;
        this.isCompleted = ko.pureComputed(() => this.status().completed);
        this.error = ko.observable(this.status().name === 'failed');
        this.canceled = ko.observable(this.status().name === 'canceled');
        this.canCancel = ko.observable(!this.isCompleted());
        this.dateSubmitted = utils.formatDateTime(this.job.data.submissionTimestamp);
        this.dateStarted = ko.observable(
            moment.utc(this.job.data.startTimestamp).local().year() > 2000
                ? utils.formatDateTime(this.job.data.startTimestamp)
                : null
        );
        this.timeRemaining = ko.observable(this.calculateTimeRemaining());
        this.dateFinished = ko.observable(
            moment.utc(this.job.data.endTimestamp).local().year() > 2000
                ? utils.formatDateTime(this.job.data.endTimestamp)
                : null
        );
        this.startDateInternal = this.job.data.startTimestamp;
        this.reason = ko.observable(
            this.isCompleted() && this.error()
                ? failedReasons[this.job.data.statusData] || failedReasons.default
                : false
        );
        this.duration = this.isCompleted()
            ? ko.observable(utils.formatDuration(this.job.data.startTimestamp, this.job.data.endTimestamp))
            : ko.observable(
                moment.utc(this.startDateInternal).local().year() > 2000
                    ? utils.formatDuration(this.startDateInternal, new Date().toISOString())
                    : null
            );

        this.sourceStorageProvider = this.job.sourceStorageProvider;
        this.sourcePath = this.job.sourcePath;
        this.sourceFileName = this.job.sourceFileName;
        this.outputStorageProvider = this.job.outputStorageProvider;
        this.outputLocation = ko.observable(
            ['export_pdf'].includes(this.job.type) && !this.status().completed
                ? null
                : this.job.outputLocation
        );

        this.type = 'job';

        this.statusHandler = {
            complete: () => this.finish(),
            failed: () => this.setErrorStatus(),
            canceled: () => this.setCanceledStatus()
        };

        this.expandInfo = ko.observable(false);
        this.reportSend = ko.observable(job.data.issue_reported);

        this.info = ko.computed(() => {
            return {
                ...(this.sourcePath
                    ? { [gettext('Source:')]: this.sourcePath }
                    : {}
                ),
                ...(this.outputLocation()
                    ? { [this.isResultAFolder ? gettext('Output location:') : gettext('Result:')]: this.outputLocation() }
                    : {}
                ),
                [gettext('Submitted from:')]: this.origin,
                [gettext('Submitted at:')]: this.dateSubmitted,
                ...(this.dateStarted()
                    ? { [gettext('Started at:')]: this.dateStarted() }
                    : {}
                ),
                ...(this.dateFinished()
                    ? { [this.status().name === 'canceled' ? gettext('Canceled at:') : gettext('Finished at:')]: this.dateFinished() }
                    : {}
                ),
                ...(this.dateStarted() && !this.isCompleted() && this.duration()
                    ? { [gettext('Duration:')]: this.duration() }
                    : {}
                ),
                ...(this.lastUpdate()
                    ? { [gettext('Last update:')]: this.lastUpdate() }
                    : {}
                ),
                ...(this.dateStarted() && !this.dateFinished() && this.timeRemaining()
                    ? { [gettext('Time until job times out:')]: this.timeRemaining() }
                    : {}
                ),
                ...((this.error() || this.canceled()) && this.lastUpdate()
                    ? { [gettext('Last update:')]: this.lastUpdate() }
                    : {}
                ),
                ...(this.reason()
                    ? { [gettext('Failure reason:')]: this.reason() }
                    : {})
            };
        });

        this.what = this.type;
        this.which = `${this.job.type.replace('_', '-')}:${this.sourceFileName}`;
    }

    guessProviderIcon (p) {
        const provider = StorageProvider.guessFromPreviewPath(p);
        return provider && provider.icon;
    }

    setupTimeRemainingInterval () {
        if (this.updateInterval) {
            return;
        }

        this.updateInterval = setInterval(() => {
            this.duration(utils.formatDuration(this.startDateInternal, new Date().toISOString()));
            this.timeRemaining(this.calculateTimeRemaining());
        }, 1000);
    }

    toggleInfo () {
        this.expandInfo(!this.expandInfo());
        if (this.expandInfo() && this.dateStarted() && !this.isCompleted() && !this.updateInterval) {
            this.setupTimeRemainingInterval();
        } else if (this.updateInterval) {
            clearInterval(this.updateInterval);
            this.updateInterval = null;
        }
    }

    reportIssue () {
        Dialog.open({
            component: 'dialog-report-job-issue',
            ctx: {
                job: this.job
            }
        })
            .result
            .then(() => this.reportSend(true));
    }

    calculateTimeRemaining () {
        if (this.dateStarted()) {
            const timeoutHours = this.job.type === 'animation' ? 48 : 24;
            const end = moment.utc(this.startDateInternal).local().add(timeoutHours, 'hours').toISOString();

            return utils.formatDuration(new Date().toISOString(), end);
        }
    };

    start () {
        this.status(JobStatus.preparing);
        this.progress('0%');
        this.percentComplete(0);
    }

    cancel () {
        this.canCancel(false);

        const job = this;
        jobApi.cancelJob(job)
            .then(data => {
                if (data.status !== 'canceled') {
                    Dialog.open({
                        component: 'alert',
                        ctx: {
                            template: { text: gettext('The job could not be canceled. It is either in progress or complete.') }
                        }
                    });
                }
            }, () => {
                Dialog.open({
                    component: 'alert',
                    ctx: {
                        template: { text: gettext('The job could not be canceled. It is either in progress or complete.') }
                    }
                });
            });
    }

    updateProgress (status, percentComplete) {
        this.status(JobStatus[status]);
        this.statusHandler[status] && this.statusHandler[status]();
    }

    updateLastUpdate (lastUpdate) {
        this.lastUpdate(handleLastUpdate(lastUpdate));
    }

    finish () {
        this.percentComplete(100);
        this.canCancel(false);
        this.status(JobStatus.complete);
        toast('JOB_COMPLETE');
        handleCompletedJob(this);
    }

    setCanceledStatus () {
        this.canceled(true);
        this.canCancel(false);
        this.percentComplete(100);
        this.status(JobStatus.canceled);
        handleCompletedJob(this);
    }

    setErrorStatus () {
        this.error(true);
        this.canCancel(false);
        this.percentComplete(100);
        this.status(JobStatus.failed);
        toast('JOB_FAIL');
        handleCompletedJob(this);
    }

    view () {
        const result = this.status().name === 'complete' && this.job.getResult();
        if (result) {
            Dialog.dismiss(); // eslint-disable-line
            if (result.isFolder) {
                import('../vendor/ko-component-router/index').then((Router) =>
                    Router.default.update(`${Settings.FILES_PREFIX}${result.storageType}/${result.owner}/${result.prefix}`)
                );
            } else {
                filePreview.openFromParams({ ...result, path: result.prefix });
            }
        } else {
            toast('RESOURCE_DOES_NOT_EXIST');
        }
    };
}

function getJobOrigin (origin) {
    if (origin === 'automatic_task') {
        return gettext('Automatic task');
    }
    if (origin.includes('VCS-iOS')) {
        return gettext('Nomad on iOS');
    }
    if (origin.includes('VCS-Android')) {
        return gettext('Nomad on Android');
    }
    if (origin.includes('VCS-DCC')) {
        const ua = parser(origin);
        return interpolate(
            gettext('Vectorworks on %(os)s'),
            { os: ua.os.name },
            true
        );
    }

    const ua = parser(origin);
    return interpolate(
        gettext('%(browser)s on %(os)s'),
        { browser: ua.browser.name, os: ua.os.name },
        true
    );
};

function trackJobs () {
    loadActiveJobs();
    pushNotifier.listen('job', 'v1').subscribe(({ event, data }) => {
        const jobId = data.id;
        const job = progressTracker.get(jobId);
        if (job) {
            trackJobEvent(job, data);
        } else { // message about nonexisting job arrived
            jobApi.getJob(jobId).then(job => progressTracker.add(JobProcess.fromJob(job)));
        }
    });
    trackPresentationJobs();
}

function trackJobEvent (jobProcess, data, job) {
    if (data.sequence_number > jobProcess.sequenceNumber()) {
        if (job) {
            jobProcess.job = job;
        }
        jobProcess.percentComplete(progressFromLastUpdate(data.last_update));
        jobProcess.updateProgress(data.status);
        jobProcess.updateLastUpdate(data.last_update);
        jobProcess.sequenceNumber(data.sequence_number);
        jobProcess.startDateInternal = data.start_timestamp;

        jobProcess.dateStarted(moment.utc(data.start_timestamp).local().year() > 2000
            ? utils.formatDateTime(data.start_timestamp)
            : null);
        jobProcess.dateFinished(moment.utc(data.end_timestamp).local().year() > 2000
            ? utils.formatDateTime(data.end_timestamp)
            : null);

        jobProcess.reason(data.status === 'failed' && data.status !== 'none' ? failedReasons[data.statusData] || failedReasons.default : null);
        jobProcess.setupTimeRemainingInterval();
    }
}

const srcStorage = data => {
    return ['publish', 'panorama', 'photogram']
        .includes(
            (data.job_type === 'generic')
                ? data.options.operation
                : data.job_type
        )
        ? data.options.src_storage_type
        : data.file_version.provider;
};

const outStorage = data => {
    return data?.options?.output_storage_type || data.file_version.provider;
};

const requestConfig = {
    validateStatus: function (status) {
        return status < 500; // Resolve only if the status code is less than 500
    }
};

const loadExternalPagesIfNeeded = jobs => {
    const googleDrivePages = jobs.filter(j => [srcStorage(j), outStorage(j)].includes('google_drive')).length
        ? loadGooglePages(false, true, requestConfig)
        : Promise.resolve([]);
    const oneDrivePages = jobs.filter(j => [srcStorage(j), outStorage(j)].includes('one_drive')).length
        ? loadOneDrivePages(false, true, requestConfig)
        : Promise.resolve([]);

    return Promise.all([googleDrivePages, oneDrivePages]);
};

function loadActiveJobs () {
    progressTracker.loading(true);

    jobApi.getAllActiveJobs()
        .then(jobs =>
            loadExternalPagesIfNeeded(jobs)
                .then(() => {
                    jobs.forEach(j => {
                        progressTracker.add(JobProcess.fromJob(new Job(j)));
                    });
                    progressTracker.loading(false);
                })
        );
};

function handleCompletedJob (jobProcess) {
    const jobRequest = jobProcess.job.data.status === 'complete'
        ? Promise.resolve(jobProcess.job)
        : jobApi.getJob(jobProcess.job.id);

    jobRequest.then(job => {
        const result = job.getResult();
        if (result && !result.isFolder) {
            pubsub.publish('job.completed', result);
            jobProcess.job = job;
            jobProcess.startDateInternal = job.data.start_timestamp;
            jobProcess.dateStarted(moment.utc(job.data.startTimestamp).local().year() > 2000
                ? utils.formatDateTime(job.data.startTimestamp)
                : null);
            jobProcess.dateFinished(moment.utc(job.data.endTimestamp).local().year() > 2000
                ? utils.formatDateTime(job.data.endTimestamp)
                : null);
            jobProcess.reason(
                job.data.status === 'failed' && job.data.statusData !== 'none'
                    ? failedReasons[job.data.statusData] || failedReasons.default
                    : null
            );
            jobProcess.updateInterval && clearInterval(jobProcess.updateInterval);
            jobProcess.duration(utils.formatDuration(job.data.startTimestamp, job.data.endTimestamp));
            jobProcess.timeRemaining(null);
            jobProcess.updateLastUpdate(job.data.lastUpdate);
            jobProcess.outputLocation(job.outputLocation);
        }
    });
}

class PreviousJob extends Job {
    constructor (data) {
        super(data);

        this.canceled = data.status === 'canceled';
        this.error = data.status === 'failed';
        this.status = JobStatus[data.status];
        this.origin = getJobOrigin(data.origin);
        this.dateSubmitted = utils.formatDateTime(data.submissionTimestamp);
        this.dateStarted = moment.utc(data.startTimestamp).local().year() > 2000
            ? utils.formatDateTime(data.startTimestamp)
            : null;
        this.dateFinished = moment.utc(data.endTimestamp).local().year() > 2000
            ? utils.formatDateTime(data.endTimestamp)
            : null;
        this.reason = data.status === 'failed' && data.statusData !== 'none' ? failedReasons[data.statusData] || failedReasons.default : null;
        this.duration = utils.formatDuration(data.startTimestamp, data.endTimestamp);
        this.lastUpdate = data.lastUpdate && (data.status === 'failed' || data.status === 'canceled') ? handleLastUpdate(data.lastUpdate) : null;
        this.expandInfo = ko.observable(false);
        this.reportSend = ko.observable(data.issue_reported);

        this.info = ko.computed(() => ({
            ...(this.sourcePath
                ? { [gettext('Source:')]: this.sourcePath }
                : {}
            ),
            ...(this.outputLocation
                ? { [this.isResultAFolder ? gettext('Output location:') : gettext('Result:')]: this.outputLocation }
                : {}
            ),
            [gettext('Submitted from:')]: this.origin,
            [gettext('Submitted at:')]: this.dateSubmitted,
            ...(this.dateStarted
                ? { [gettext('Started at:')]: this.dateStarted }
                : {}
            ),
            ...(this.dateFinished
                ? { [this.status.name === 'canceled' ? gettext('Canceled at:') : gettext('Finished at:')]: this.dateFinished }
                : {}
            ),
            ...(this.dateStarted && this.dateFinished
                ? { [gettext('Duration:')]: this.duration }
                : {}
            ),
            [gettext('Status:')]: this.status.text,
            ...(this.lastUpdate
                ? { [gettext('Last update:')]: this.lastUpdate }
                : {}
            ),
            ...(this.reason
                ? { [gettext('Failure reason:')]: this.reason }
                : {})
        }));

        this.what = 'job';
        this.which = `${this.type.replace('_', '-')}:${this.sourceFileName}`;
    }

    guessProviderIcon (p) {
        const provider = StorageProvider.guessFromPreviewPath(p);
        return provider && provider.icon;
    }

    toggleInfo () {
        this.expandInfo(!this.expandInfo());
    }

    reportIssue () {
        Dialog.open({
            component: 'dialog-report-job-issue',
            ctx: {
                job: this.data
            }
        })
            .result
            .then(() => this.reportSend(true));
    }

    getResult () {
        return this.data.status !== 'complete'
            ? null
            : super.getResult();
    }

    view () {
        const result = this.getResult();

        if (result) {
            Dialog.dismiss(); // eslint-disable-line

            if (result.isFolder) {
                import('../vendor/ko-component-router/index').then((Router) =>
                    Router.default.update(`${Settings.FILES_PREFIX}${result.storageType}/${result.owner}/${result.prefix}`)
                );
            } else {
                filePreview.openFromParams({ ...result, path: result.prefix });
            }
        } else {
            toast('RESOURCE_DOES_NOT_EXIST');
        }
    };
}

export {
    trackCopyFiles,
    trackJobs,
    trackJobEvent,
    loadExternalPagesIfNeeded,
    loadActiveJobs,
    Job,
    JobProcess,
    PreviousJob
};
